import {
  PdfWorkerGenerateDocumentMessage,
  PdfWorkerMessage
} from '@property-folders/common/util/pdf/pdf-worker-types';
import { Pdf } from '@property-folders/common/util/pdf-worker/Pdf';
import {
  salesAgreementVariation,
  residentialSalesContract,
  residentialSalesContractVariation,
  residentialSalesAgreement
} from '@property-folders/common/util/pdfgen/definitions';
import { sampleSaa } from '@property-folders/common/util/pdfgen/definitions/samples/sample-saa';
import { generateSimpleCoverPageDefinition } from '@property-folders/common/util/pdfgen/definitions/documents/simple-cover-page';
import { stitch } from '@property-folders/common/util/pdf/pdf-stitch';
import { generatePdf } from '@property-folders/common/util/pdfgen/definitions/documents/sales-contract-cover-page';
import { generateCoverPageDefinition } from '@property-folders/common/util/pdfgen/definitions/documents/saa-cover-page';
import { contractSaleTerminationMutual } from '@property-folders/common/util/pdfgen/definitions/documents/sales-contract-termination';
import { salesContractTerminationExternal } from '@property-folders/common/util/pdfgen/definitions/documents/sales-contract-termination-external';
import {
  residentialPurchaseOffer
} from '@property-folders/common/util/pdfgen/definitions/documents/residential-purchase-offer';
import { extractRange, split } from '@property-folders/common/util/pdf/pdf-split';
import { AbortRequest, isAbortRequest, isUint8ArrayBuffer } from '../../web-workers/communication';

async function generateForm(data: PdfWorkerGenerateDocumentMessage, pdf: Pdf) {
  const {
    brand,
    meta,
    formType,
    requestId,
    noBoldContentMode
  } = data;
  let def: any = null;

  switch (formType) {
    case 'ResidentialSalesAgreementPDF':
      def = residentialSalesAgreement(data);
      break;

    case 'SalesAgreementVariationPDF':
      def = salesAgreementVariation(data);
      break;

    case 'ResidentialSalesContractPDF':
      def = residentialSalesContract(data);
      break;

    case 'ResidentialSalesContractVariationPDF':
      def = residentialSalesContractVariation(data);
      break;

    case 'contractSaleTerminationMutualPDF':
      def = contractSaleTerminationMutual(data);
      break;

    case 'salesContractTerminationExternalPDF':
      def = salesContractTerminationExternal(data);
      break;

    case 'ResidentialPurchaseOfferPDF':
      def = residentialPurchaseOffer(data);
      break;

    case 'SampleSaaPDF':
      def = sampleSaa(data);
      break;

    default:
      console.error('unknown formType', formType);
      return;
  }

  pdf
    .prepare(brand, meta, noBoldContentMode)
    .generateBuffer(def, blob => {
      const buffer = isUint8ArrayBuffer(blob) ? blob.buffer : blob;
      self.postMessage({ requestId, blob: buffer }, [buffer]);
    });
}

function generateCoverPage(data: any, pdf: Pdf) {
  const {
    brand,
    meta,
    formType,
    requestId,
    noBoldContentMode
  } = data;

  let def: any = null;

  switch (formType) {
    case 'ResidentialSalesAgreementPDF':
      def = generateCoverPageDefinition(data);
      break;

    case 'SalesAgreementVariationPDF':
      return;

    case 'ResidentialSalesContractPDF':
      def = generatePdf(data);
      break;

    case 'ResidentialSalesContractVariationPDF':
      return;

    case 'contractSaleTerminationMutualPDF':
      return;

    case 'salesContractTerminationExternalPDF':
      return;

    case 'ResidentialPurchaseOfferPDF':
      return;

    case 'SampleSaaPDF':
      return;

    case 'simple':
      def = generateSimpleCoverPageDefinition(data);
      break;

    default:
      console.error('unknown formType', formType);
      return;
  }

  pdf
    .prepare(brand, meta, noBoldContentMode)
    .generateBuffer(def, blob => {
      const buffer = isUint8ArrayBuffer(blob) ? blob.buffer : blob;
      self.postMessage({ requestId, blob: buffer }, [buffer]);
    });
}

const pdf = new Pdf();
self.onmessage = (e: MessageEvent<PdfWorkerMessage | AbortRequest>) => {
  try {
    const data = e.data;

    if (isAbortRequest<PdfWorkerMessage>(data)) {
      return;
    }

    switch (data.type) {
      case 'generateDocument':
        generateForm(data, pdf);
        break;

      case 'stitchPdf':
        stitch(data.pdfs, data.idxBase).then(blob => {
          const buffer = isUint8ArrayBuffer(blob) ? blob.buffer : blob;
          self.postMessage({
            requestId: data.requestId,
            blob: buffer
          }, [buffer]);
        });
        break;

      case 'splitPdf':
        split(data.pdf, data.splits, { writeLock: data.writeLock })
          .then(buffer => {
            self.postMessage({
              requestId: data.requestId,
              blob: buffer
            });
          });
        break;

      case 'extractPdfRange':
        extractRange(data.pdf, data.start, data.exclusiveEnd, { writeLock: data.writeLock })
          .then(buffer => {
            self.postMessage({
              requestId: data.requestId,
              blob: buffer
            });
          });
        break;

      case 'generateAnnexure':
        pdf.generateAnnexure({
          buffers: data.pdfs,
          annexure: data.annexure,
          brand: data.brand,
          coversheet: data.coversheet
        })
          .then(blob => {
            const buffer = isUint8ArrayBuffer(blob) ? blob.buffer : blob;
            self.postMessage({
              requestId: data.requestId,
              blob: buffer
            }, [buffer]);
          });
        break;

      case 'generateCoverPage':
        generateCoverPage(data, pdf);
        break;

      default:
        console.error('Unknown worker message received');
    }

  } catch (e) {
    console.log(e);
  }
};

export {};
